export const FirebaseConfig = {
	"projectId": "dehtransportsexprelc",
	"appId": "1:706340455861:web:a6f5d1ca586c43a10d05fc",
	"databaseURL": "https://dehtransportsexprelc-default-rtdb.firebaseio.com",
	"storageBucket": "dehtransportsexprelc.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBW-c7ZeNwAGQ6Sut6ngK-_X00gx4ufNkA",
	"authDomain": "dehtransportsexprelc.firebaseapp.com",
	"messagingSenderId": "706340455861",
	"measurementId": "G-YPD3CZDJN7"
};